:root {
  --header-top-size: 64px;
}

html {
  scroll-padding-top: 111px;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
}

button,
input,
select,
option {
  font-family: "Roboto", sans-serif;
}

* {
  -webkit-tap-highlight-color: transparent;
}

::placeholder {
  color: rgba(0, 0, 0, 0.22);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.22);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.22);
}

.w100p {
  width: 100%;
}

.flex {
  display: flex;
}

.center-row {
  justify-content: center;
}

.center-column {
  align-items: center;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.flex-max {
  flex: 1;
}

.circle {
  border-radius: 50%;
}

.wrap {
  flex-wrap: wrap;
}

.margin-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.text-wrap {
  text-wrap: wrap !important;
}

.fit-content {
  width: fit-content !important;
}

.mobile {
  display: none !important;
}

.desktop {
  display: flex !important;
}

#lgses {
  position: fixed;
  bottom: 0px;
  font-size: 0.75em;
  width: 100%;
  overflow: hidden;
  z-index: 1000;
  max-height: 35%;
  background-color: rgba(255, 255, 255, 1);
  padding: 5px 0px;
  display: none;
  border-top: 1px solid #ebebeb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
#lgses p {
  margin: 0px;
}

#lgses .lgsCloseButton {
  position: absolute;
  right: 10px;
  bottom: 0px;
  font-size: 1.3em;
}

#lgses .lgsVersion {
  position: fixed;
  top: 1px;
  left: 3px;
  color: white;
  font-size: 1.1em;
}

/* Tooltip */

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 6px;
  position: absolute;
  z-index: 8;
  top: 100%;
  left: 50%;
  margin-top: 12px;
  margin-left: -60px;
  font-size: 14.4px;
  font-weight: normal;
}

.tooltip.right .tooltiptext {
  margin-left: -100px;
}

.tooltip.top .tooltiptext {
  top: -20px;
  transform: translateY(-100%);
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip.right .tooltiptext::after {
  left: 80%;
}

.tooltip.top .tooltiptext::after {
  top: 100%;
  left: 50%;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* Tooltip */

select {
  background-color: #31c48d;
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  border-radius: 2px;
  border: none;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  outline: none;
  border-radius: 5px;
}

header {
  background-color: #31c48d;

  box-shadow: 0 4px 13px -3px rgba(0, 0, 0, 0.10196);
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;

  height: 64px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 20;
}

header .menu {
  color: white;
  font-size: 2em;
  padding: 10px 8px 10px 15px;
  cursor: pointer;
  display: none;
}

header > a {
  width: 210px;
  display: block;
}

header .logo {
  background-image: url("talkvio-logo_cut.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 64px;
  width: 210px;
}

header nav {
  flex-grow: 2;
}

header nav a {
  color: white !important;
  padding: 15px 10px;
  display: inline-block;
}

header nav .onlyLeft {
  display: none;
}

header nav a:hover {
  color: white;
}
header nav a:visited {
  color: white;
}

header .socialNetworks {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
}

header .socialNetworks svg {
  font-size: 2em;
  color: white;
  margin: 5px;
  padding: 5px;
}

header .socialNetworks .description {
  font-size: 0.75em;
  color: white;
  text-align: center;
}

header .loginInfo {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  margin-left: 25px;
  color: white;
}

header .loginInfo .loginButton {
  margin-right: 18px;
  cursor: pointer;
  padding: 15px 5px;
}

header .loginInfo .loginButton svg {
  margin-right: 8px;
}

header .loginInfo .username {
  color: white;
  font-weight: 600;
  cursor: pointer;
  display: inline-grid;
}

header .loginInfo .username a {
  color: white;
  text-overflow: ellipsis;
  overflow: hidden;
}

header .loginInfo .username a:visited {
  color: white;
}

header .loginInfo .avatar {
  padding: 5px;
  border-radius: 50%;
  margin: 10px;
}

header .loginInfo .avatar img {
  border-radius: 50%;
  height: 42px;
  width: 42px;
  object-fit: cover;
}

header nav.leftMenu {
  position: fixed;
  display: flex;
  flex-direction: column;
  background: white;
  top: var(--header-top-size);
  left: 0px;
  z-index: 20;
  width: 210px;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: calc(100% - var(--header-top-size) + 1px);
}

header nav.leftMenu > a {
  color: #000 !important;
  padding: 18px;
  border-bottom: 1px solid #dcdcdc;
}

header nav.leftMenu > a:visited {
  color: #000 !important;
}

header .rightMenu {
  position: fixed;
  display: flex;
  flex-direction: column;
  background: white;
  top: var(--header-top-size);
  right: 0px;
  z-index: 20;
  width: 210px;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: calc(100% - var(--header-top-size) + 1px);
}

header .rightMenu > a {
  color: #000 !important;
  padding: 18px;
  border-bottom: 1px solid #dcdcdc;
}

header .rightMenu > a:visited {
  color: #000 !important;
}

header .rightMenu svg,
header .leftMenu svg {
  margin-right: 8px;
}

header .mainMenu svg {
  display: none;
}

footer {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  justify-content: center;
  font-size: 0.88em;
  color: #888;
}

.App {
  background: #eee;
  min-height: 100vh;
  overflow-y: clip;
  overflow-x: hidden;
}

@supports (overflow-x: clip) {
  .App {
    overflow-x: clip;
  }
}

.pagecontent {
  margin-top: 64px;
}

.pointer {
  cursor: pointer;
}

/* Links */

a:link {
  color: #5b9b17;
  text-decoration: none;
}

a:visited {
  color: #457612;
  text-decoration: none;
}

/* Input */

.text-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.text-input input {
  border: 0px;
  padding: 7px;
  border-bottom: 1px solid #dcdcdc;
  margin: 5px;
  font-size: 1.1em;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0);
}

.text-input input:focus {
  outline: none;
}

.text-input .placeholder {
  font-size: 1.1em;
}

.select-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.select-box .placeholder {
  margin-right: 10px;
  font-size: 1.1em;
}

.select-box img {
  max-width: 100%;
}

/* Editor */

header .addPost {
  cursor: pointer;
  margin-left: 30px;
  margin-right: 25px;
}

header .languageSwitcher {
  padding: 0px;
  font-size: 1.05em;
}

header .addPost svg {
  color: white;
  font-size: 1.5em;
  padding: 6px;
  border: 2.5px solid #fff;
  border-radius: 10px;
}

header .searchPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 20px;

  transition: 0.5s;
}

header .searchPanel input {
  border: 0px;
  outline: 0;
  padding: 7px 20px;
  font-size: 1.04em;
  background-color: rgba(0, 0, 0, 0);
  color: #595959;
  max-width: 150px;

  transition: 0.5s;
}

header .searchPanel .searchButton {
  color: #aaa;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9em;
}

header .avatar {
  position: relative;
}

header .notificationIcon {
  position: absolute;
  right: 1px;
  bottom: 1px;
  background: #f50253;
  padding: 5px;
  border-radius: 50%;
  font-size: 0.65em;
  width: 12px;
  height: 12px;
  text-align: center;
  border: 2px solid #dcdcdc;
  cursor: pointer;
}

header .notificationList {
  position: fixed;
  top: var(--header-top-size);
  right: 0px;
  background: white;
  color: black;
  padding: 20px;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  list-style: none;
  margin: 0px;
  overflow-y: auto;
  max-height: calc(100% - 100px);
}

header .notificationList .notification {
  padding: 15px 0px;
  border-bottom: 1px solid #dcdcdc;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
}

header .notificationList .notification svg {
  margin-right: 10px;
  margin-left: 3px;
}

header .notificationList .notification.readed {
  color: rgba(0, 0, 0, 0.2);
}

header .notificationList .notification.more {
  text-align: center;
}

header .notificationList .notification.profile {
  text-align: center;
}

@media only screen and (max-width: 1488px) {
  header .searchPanel:not(.enter) {
    background: rgba(0, 0, 0, 0) !important;
  }

  header .searchPanel.enter input {
    width: 180px;
  }

  header .searchPanel input {
    width: 0px;
  }

  header .searchPanel:not(.enter) .searchButton {
    color: white;
    font-size: 1.3em;
  }

  header.searching .logoContainer {
    flex-grow: 2;
  }

  header.searching .mainMenu {
    transition: 0.5s;
    display: none;
  }
}

@media only screen and (max-width: 860px) {
  header.searching .menu {
    flex-grow: 2;
  }

  header.searching .logoContainer {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  header .searchPanel input {
    max-width: 100px;
  }

  header.searching .searchPanel input {
    width: 100%;
    max-width: initial;
  }
}

@media only screen and (max-width: 370px) {
  header.searching .menu {
    display: none !important;
  }
}

@media only screen and (max-width: 320px) {
  header:not(.searching) .searchPanel input {
    display: none;
  }

  header.searching .searchPanel input {
    width: 100%;
    max-width: initial;
  }

  header.searching .addPost {
    display: none;
  }

  header.searching .avatar {
    display: none;
  }
}

.context-menu {
  position: relative;
}

.context-menu .menu-list {
  position: absolute;
  top: 100%;
  left: 0px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18);
  padding: 2px;
  z-index: 10;
  font-size: initial;
}

.context-menu .menu-list.right,
.context-menu.right .menu-list {
  right: 0px;
  left: inherit;
}

.context-menu .menu-caller {
  cursor: pointer;
}

.context-menu .menu-list .menu-item {
  cursor: pointer;
  font-size: 0.92em;
  padding: 8px;
  transition: 0.3s;
  color: rgba(0, 0, 0, 0.84);
  border-radius: 5px;
  white-space: nowrap;
  font-weight: 500;
  z-index: 10;
}
.context-menu .menu-list .menu-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.dark-mode .context-menu .menu-list {
  background-color: rgb(38, 43, 48);
}

.dark-mode .context-menu .menu-list .menu-item {
  color: #e0e3ea;
}

.dark-mode .context-menu .menu-list .menu-item:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

.App > .editor {
  position: fixed;
  display: flex;
  flex-direction: column;

  top: var(--header-top-size);
  background-color: #fff;
  padding: 0px 15px;
  padding-bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;

  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);

  width: 100%;
  max-width: 850px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100% - 100px);

  z-index: 11;

  scroll-padding-top: 52px;
}

@media only screen and (max-width: 850px) {
  .App > .editor {
    border-radius: 0px;
  }
}

@media only screen and (max-width: 500px) {
  .App > .editor {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .App > .editor::-webkit-scrollbar {
    display: none;
  }
}

.normalBackground {
  background: #eee;
  padding: 10px 0px 20px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.dark-mode .normalBackground {
  background: #161a1d;
}

.typicalBlock {
  background: white;
  border-radius: 8px;
}

.settingsPage,
.blocksPage {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.settingsPage {
  margin-top: 90px;
}

.specialPage {
  box-sizing: border-box;
  display: flex;
  background: #fff;
  border: 1px solid #dcdcdc;
  padding: 30px;
  margin-top: 30px;
  max-width: 800px;
  overflow-wrap: anywhere;
}

.dark-mode .specialPage {
  background: rgb(38, 43, 48);
  border: 1px solid #0f0f0f;
}

@media only screen and (max-width: 500px) {
  .specialPage {
    padding: 20px 10px;
    border-left: 0px;
    border-right: 0px;
  }
}

.specialPage img {
  max-width: 100%;
}

.specialPage h2 {
  text-align: center;
}

.smallListPadding li {
  padding: 5px 2px;
}

.askRegistationBlock {
  background: white;
  padding: 15px;
  margin-left: 15px;
  max-width: 300px;
  border-radius: 10px;
  cursor: pointer;
}

.forumName {
  display: flex;
  flex-direction: row;

  align-items: center;
  padding: 0px 8px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
}

.forumName div {
  padding: 3px 0px;
}

.forumName .text-input {
  padding: 0px 10px;
}

.forumName .selectedForum {
  font-size: 1.1em;
  border: 1px solid #e61856;
  padding: 8px 15px;
  border-radius: 20px;
}

.forumName .selectedForumRemove {
  padding: 4px 8px 4px 6px;
  font-size: 1.2em;
  cursor: pointer;
  color: #595959;
}

.forumName .selectedForumDesc {
  font-size: 1.1em;
  margin-right: 8px;
  padding-left: 10px;
}

.forumName .forumSelection {
  position: relative;
  padding: 0px 10px;
  overflow: hidden;
}

.forumName .forumSelection .forumSelectionIcon {
  font-size: 1.1em;
  cursor: pointer;
  color: #595959;
}

.forumName .forumSelection select {
  opacity: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: hidden;
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .forumName select {
    max-width: 210px;
  }
}

.postsViewContainer .searchBox,
.usersViewContainer .searchBox {
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: right;
}

.postsViewContainer .searchPanel,
.usersViewContainer .searchPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 20px;
  margin-top: 10px;
  transition: 0.5s;
  width: fit-content;
}

@media only screen and (max-width: 710px) {
  .postsViewContainer .searchPanel,
  .usersViewContainer .searchPanel {
    margin-right: 1px;
  }
}

.postsViewContainer .searchPanel input,
.usersViewContainer .searchPanel input {
  border: 0px;
  outline: 0;
  padding: 7px 20px;
  font-size: 1.04em;
  background-color: rgba(0, 0, 0, 0);
  color: #595959;
  max-width: 150px;

  transition: 0.5s;
}

.postsViewContainer .searchPanel .searchButton,
.usersViewContainer .searchPanel .searchButton {
  color: #aaa;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9em;
}

.dark-mode .postsViewContainer .searchPanel,
.dark-mode .usersViewContainer .searchPanel {
  background: rgb(38, 43, 48);
}

.dark-mode .postsViewContainer .searchPanel input,
.dark-mode .usersViewContainer .searchPanel input {
  color: #e0e3ea;
}

.connectedPostsBlock {
  display: flex;
  flex-direction: column;

  padding: 0px 8px 8px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
}

.connectedPostsBlock .text-input {
  padding-left: 10px;
}

.connectedPostsBlock .addConnectedPost {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.connectedPostsBlock .addConnectedPost .fa-link {
  cursor: pointer;
  color: #595959;
}

.connectedPostsBlock .connectedPosts {
  display: flex;
  flex-direction: column;
  padding: 8px 8px;
}

.connectedPostsBlock .connectedPosts .connectedPost {
  cursor: pointer;
  padding: 10px 10px;
  font-size: 1.1em;
  border: 0px solid #eee;
  border-bottom: 1px solid #eee;
  color: #444;
  flex-direction: row;
  display: flex;
}
.connectedPostsBlock .connectedPosts .removeButton {
  cursor: pointer;
  margin-left: 10px;
}

.connectedPostsBlock .connectedPostsTitle {
  font-size: 1.1em;
  padding: 8px 8px;
}

.connectedPostsBlock .connectedPostsTitle svg {
  font-size: 0.9em;
}

.connectedPostSearchList {
  list-style: none;
  padding: 0;
  margin: 5px 0;

  li {
    padding: 8px 12px;
    cursor: pointer;
  }
}

.gender {
  font-size: 0.6em;
  padding-left: 5px;
}

.gender .male {
  color: #13dcf2;
}

.gender .female {
  color: #f213b7;
}

.subscriptionButton.unsubscribe {
  background-color: #eb345e !important;
}

.subscriptionButtonBlock.unsubscribe {
  background-color: #eb345e !important;
}

.subscriptionButtonBlock {
  margin: -5px 10px 10px !important;
}

.tagSubscriptionButton.unsubscribe {
  background-color: #eb345e !important;
}
.tagSubscriptionButton {
  font-size: 14px;
  margin: 0px 8px !important;
}

.tagBlockButton.unblock {
  background-color: #1d7a58 !important;
}
.tagBlockButton {
  font-size: 14px;
  background-color: #eb345e !important;
  margin: 0px 8px !important;
}

.blockButton.block {
  background-color: #eba234 !important;
}

.blockButtonBlock.block {
  background-color: #eba234 !important;
}

.blockButton.unblock {
  background-color: #eb345e !important;
}

.blockButtonBlock.unblock {
  background-color: #eb345e !important;
}

.settings {
  background: white;
  border-radius: 6px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.settings .splitter {
  border-bottom: 1px solid #f3f4f5;
  padding: 5px 0px;
  margin: 14px 0px 10px;
  color: #70737b;
  font-size: 15.2px;
}

.settings .splitter svg {
  margin-right: 7px;
}

.dark-mode .settings .splitter {
  border-bottom: 1px solid #444;
  color: #b9bcc4;
}

.settings .title {
  font-weight: 500;
  font-size: 1.4em;
}

.settings .settingsList {
  list-style: none;
  padding: 0px;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
}

.settings .settingsList li {
  padding-top: 6px;
  padding-bottom: 6px;
}

.settings .settingsList .checkbox .checkbox-text {
  margin-top: 2px;
}

.settings .placeholder {
  width: 130px;
  display: block;
}

.leftPostsPanel {
  margin-top: 26px;
  max-width: 240px;
}

.leftBlocksPanel {
  margin-top: 26px;
  width: 320px;
  display: none;
}

.rightBlocksPanel {
  margin-top: 26px;
  width: 320px;
  display: none;
}

.leftBlocksPanel.sticky {
  position: fixed;
  left: 0px;
  top: 0px;
  max-height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.leftBlocksPanel.sticky::-webkit-scrollbar {
  display: none;
}

.rightBlocksPanel.sticky {
  position: fixed;
  right: 0px;
  top: 0px;
  max-height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.rightBlocksPanel.sticky::-webkit-scrollbar {
  display: none;
}

.rightBlocksPanel.sticky .postslist.sticky {
  margin: 0px;
  padding-left: 0px;
  padding-right: 15px;
  padding-top: 0px;
}

.userCircle .circle.enabled {
  border: 6px solid #31c48d;
}

.userCircle .circle.disabled {
  border: 6px solid #e74c3c;
}

.userCircle .circle {
  width: 48px;
  height: 48px;
  object-fit: cover;
}

.leftPostsPanel .subscriptions .subscriptionsShowButton {
  cursor: pointer;
  padding: 15px;
  display: none;
}

.leftPostsPanel .subscriptions.hidden {
  justify-content: center;
}

.leftPostsPanel .subscriptions {
  flex-wrap: wrap;
  padding: 9px 0px 9px;
}

.leftPostsPanel .subscriptions > * {
  padding: 2px 9px;
}

.leftPostsPanel .subscriptions span {
  margin-left: 8px;
  cursor: pointer;
}

.leftPostsPanel .subscriptions span.enabled {
  color: #31c48d;
}

.leftPostsPanel .subscriptions span.disabled {
  color: #e74c3c;
}

.leftPostsPanel .subscriptions .oneClick {
  padding: 15px;
}

@media only screen and (max-width: 1300px) {
  .leftPostsPanel .subscriptions .subscriptionsShowButton {
    display: block;
  }

  .leftPostsPanel .subscriptions.hidden .sub {
    display: none;
  }

  .leftPostsPanel .subscriptions.hidden .oneClick {
    display: none;
  }

  .blocks-main {
    flex-direction: column;
  }

  .leftPostsPanel {
    width: 100%;
    max-width: 1045px;
    margin-left: auto;
    margin-right: auto;
    transform: translateX(-35px) !important;
  }

  .leftPostsPanel .subscriptions {
    flex-direction: row;
  }
}

/* Thread title styling */
.threadTitle {
  margin: 10px 1% 0px;
  font-size: 1.6em;
  padding: 15px 20px;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.threadTitle .titleBlock {
  flex-grow: 2;
  font-size: 0.88em;
  font-weight: 500;
}

.threadTitle .direction {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  font-size: 0.7em;
}

.threadTitle .categorySwitcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  font-size: 0.7em;
}

.categorySwitcher.withTabs {
  margin-left: 20px;
}

.threadTitle .stickPost {
  font-size: initial;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 7px 20px 7px 30px;
}

.threadTitle .stickPost .checkmark {
  top: auto;
}

.mainTitle .direction {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  font-size: 0.7em;
}

.mainTitle .categorySwitcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  font-size: 0.7em;
}

.mainTitle .categorySwitcher select {
  margin-left: 10px;
}

.mainTitle .direction div {
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.threadTitle .categorySwitcher div {
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.mainTitle {
  margin: 10px 0% 0px;
  font-size: 1.6em;
  padding: 15px 20px;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  min-height: 50px;
  justify-content: center;

  row-gap: 8px;
  column-gap: 8px;
}

.mainTitle.sectionSwitcher {
  justify-content: flex-start;
}

.mainTitle .titleText {
  flex-grow: 2;
}

.mainTitle .titleText a,
.mainTitle .titleText a:visited {
  color: #31c48d;
}

.dark-mode .mainTitle .titleText a,
.dark-mode .mainTitle .titleText a:visited {
  color: #1eb32b;
}

@media only screen and (max-width: 658px) {
  .userpage .mainTitle .titleText {
    display: none;
  }
}

@media only screen and (max-width: 1050px) {
  .userpage .mainTitle .direction {
    padding: 0px 15px;
  }
}

.mainTitle .titleText .renewPosts {
  cursor: pointer;
}

.dark-mode .mainTitle .titleText .renewPosts {
  color: #1eb32b;
}

.recentPosts .threadTitle {
  font-size: 1.4em;
}

.recentPosts .postslist {
  margin-bottom: 0px;
  margin-left: 2%;
  margin-right: 2%;
  min-width: 732px;
}

.threadTitle .forumTitle {
  margin-left: 20px;
  font-size: 0.62em;
}

.threadTitle a {
  color: black;
}

ul.postslist {
  list-style: none;
  margin: 0px 1% 10px 1%;
  padding: 15px;
  width: 100%;
  /* CLS fix */
  min-height: 100vh;

  /* border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px; */
}

ul.postslist.searchBoxPosts {
  padding-top: 2px;
}

ul.postslist .subpostslist {
  padding: 0px;
}

ul.postslist .postBody {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

ul.postslist .postBody.withLeftContainer {
  margin-left: -84px;
}

.leftPostsPanel.withLeftContainer {
  transform: translateX(-84px);
}

.recentposts.postslist .replyForm {
  margin-left: auto;
  margin-right: auto;
  max-width: 732px;
}

@media only screen and (max-width: 1200px) {
  ul.postslist .postBody.withLeftContainer {
    margin-left: 0px;
  }

  .leftPostsPanel.withLeftContainer {
    transform: initial !important;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1200px) {
  .recentposts.postslist .replyForm:not(.treeLeaf) {
    transform: translateX(42px);
  }
}

@media only screen and (max-width: 870px) {
  .recentposts.postslist .replyForm {
    transform: initial;
  }
}

.taglist {
  padding: 8px 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
}

.tag {
  border: 1px solid #31c48d;
  padding: 8px;
  border-radius: 20px;
  min-width: 50px;
  text-align: center;
  color: #31c48d;
  cursor: pointer;
  margin: 5px;
}

.blocksPage .title {
  padding: 10px 10px 0px;
  font-weight: 500;
  font-size: 1.4em;
}

.blockedTags {
  padding: 5px 10px;
}

.dark-mode .tag {
  border-color: #1eb32b;
}

.tag a,
.tag a:visited {
  color: #31c48d !important;
}

.dark-mode .tag a,
.dark-mode .tag a:visited {
  color: #1eb32b !important;
}

.tag.disabled {
  border-color: #eb345e;
  color: #eb345e;
}

.tag.disabled a,
.tag.disabled a:visited {
  color: #eb345e;
}

ul.postslist .hideAdult {
  filter: blur(48px);
}

ul.postslist.sticky .pagetext.hideAdult {
  display: none !important;
}

ul.postslist.sticky.render-wait {
  display: none;
}

ul.postslist .showAdultButton {
  background: #eb4034;
  padding: 17px;
  border-radius: 17px;
  cursor: pointer;
  color: white;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  position: sticky;
  bottom: 50%;
  left: 50%;
  margin-top: 20%;
  transform: translate(-50%, 0px);
  width: fit-content;
}

ul.postslist .leftPostFollower {
  position: sticky;
  top: calc(var(--header-top-size) - 8px);
  height: fit-content;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

ul.postslist .hideBox {
  margin-left: auto;
  margin-right: auto;
  margin-top: 4px;
  transform: translateX(-6px);
  border: 1px solid #d8d8d8;
  color: rgb(180, 180, 180);
  border-radius: 10px;
  padding: 2px 15px;
  cursor: pointer;
}

ul.postslist .leftPostPanel {
  overflow-y: clip;
  margin-bottom: 10px;
}

ul.postslist .scrollBotommer {
  cursor: pointer;
  display: block;
  height: 100%;
  box-sizing: border-box;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 15%,
    #e2e3e4 100%
  );
  opacity: 0;
  transition: opacity 200ms ease-out;
  margin-left: -8px;
  z-index: 0;
}

.dark-mode ul.postslist .scrollBotommer {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 15%,
    #616161 100%
  );
}

ul.postslist .scrollBotommer:hover {
  opacity: 1;
}

ul.postslist .post.hidden .textcenter .postTitle {
  margin-bottom: 12px;
}

ul.postslist .ratingLeftChanger {
  margin-top: 20px;
  margin-right: 12px;
  margin-left: 0px;
  margin-bottom: 5px;

  padding: 4px 0px;

  color: rgb(180, 180, 180);
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px solid #d8d8d8;
  width: 70px;
  max-height: 70px;
  border-radius: 10px;
}

ul.postslist .ratingLeftChanger svg {
  height: 15px;
  width: 20px;
  fill: rgb(180, 180, 180);
}

ul.postslist .ratingLeftChanger .raitingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

ul.postslist .ratingLeftChanger .rating {
  font-size: 1.4em;
  padding: 3px 0px;
}

ul.postslist .ratingLeftChanger .down {
  position: relative;
  cursor: pointer;
}
ul.postslist .ratingLeftChanger .down.current svg {
  fill: #ed3b50;
}
ul.postslist .ratingLeftChanger .rating.neg {
  color: #ed3b50;
}

ul.postslist .ratingLeftChanger .up {
  position: relative;
  cursor: pointer;
}
ul.postslist .ratingLeftChanger .up.current svg {
  fill: #31c48d;
}
ul.postslist .ratingLeftChanger .rating.pos {
  color: #31c48d;
}

.dark-mode ul.postslist .ratingLeftChanger .up.current svg {
  fill: #1eb32b;
}

.dark-mode ul.postslist .ratingLeftChanger .rating.pos {
  color: #1eb32b;
}

ul.postslist .ratingLeftChanger .up .rating-clicker {
  background: black;
  position: absolute;
  left: -20px;
  width: 60px;
  height: 33px;
  opacity: 0;
}

ul.postslist .ratingLeftChanger .down .rating-clicker {
  background: red;
  position: absolute;
  left: -20px;
  top: -16px;
  width: 60px;
  height: 33px;
  opacity: 0;
}

ul.postslist .ratingCenterChanger {
  padding: 5px 5px 12px 5px;
  color: rgb(180, 180, 180);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

ul.postslist .ratingCenterChanger svg {
  height: 15px;
  width: 20px;
  fill: rgb(180, 180, 180);
}

ul.postslist .ratingCenterChanger > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #dcdcdc;
  padding: 13px;
  border-radius: 8px;
}

ul.postslist .ratingCenterChanger .rating {
  font-size: 1.55em;
}

ul.postslist .ratingCenterChanger .down {
  cursor: pointer;
  margin-left: 10px;
}
ul.postslist .ratingCenterChanger .down.current svg {
  fill: #ed3b50;
}
ul.postslist .ratingCenterChanger .rating.neg {
  color: #ed3b50;
}

ul.postslist .ratingCenterChanger .up {
  cursor: pointer;
  margin-right: 10px;
}
ul.postslist .ratingCenterChanger .up.current svg {
  fill: #31c48d;
}
ul.postslist .ratingCenterChanger .rating.pos {
  color: #31c48d;
}

.dark-mode ul.postslist .ratingCenterChanger .up.current svg,
.dark-mode ul.postslist .ratingCenterChanger .rating.pos {
  color: #1eb32b;
}

ul.postslist .post {
  margin: 10px 0px 10px 0px;
  background: white;
  border-radius: 8px;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  max-width: 732px;
}

ul.postslist .postBody.tree {
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
}

ul.postslist .post.tree {
  flex-direction: column;
  margin-top: 0px;
}

ul.postslist .post .poststree {
  display: flex;
  flex-direction: row;
}

ul.postslist .post .poststree .treeroot {
  display: flex;
  flex-direction: column;
  width: 100%;
}

ul.postslist .post .collapser {
  width: 25px;
  height: calc(100% - 12px);
}

ul.postslist .post .collapser .collapser-line {
  margin-left: auto;
  margin-right: auto;
  width: 2px;
  background-color: #eee;
  height: 100%;
}

ul.postslist .post.tree .userleft {
  border-bottom: initial;
}

ul.postslist .post.tree .downcontrols {
  border-top: initial;
}

ul.postslist .post.tree .textcenter .pagetext {
  padding-top: 0px;
  padding-bottom: 0px;
}

ul.postslist .post .postcore {
  display: flex;
  flex-direction: column;
  width: 100%;
}

ul.postslist .post .userleft {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: start;
  align-items: center;
  flex-wrap: nowrap !important;
  width: 200px;
  padding: 10px 16px;
  border-bottom: 1px solid #eee;
  width: 100%;
  box-sizing: border-box;
}

ul.postslist .post.minimal .userleft {
  display: flex;
  flex-wrap: wrap;
}

ul.postslist .post .userleft .topinfo {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  flex-grow: 2;
}

ul.postslist .post.minimal .userleft img {
  height: 64px;
}

ul.postslist .post .userleft .username {
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
}

ul.postslist .post .userleft .username a {
  color: #000;
}

ul.postslist .post .userleft .user-main-block {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  column-gap: 3px;
}

ul.postslist .post .userleft .user-desc-block {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  column-gap: 6px;
}

ul.postslist .post .userleft .user-desc-block .forum {
  display: inline-grid;
}

ul.postslist .post .userleft .user-desc-block .forum a {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

ul.postslist .post .userleft .user-desc-block .forum a,
ul.postslist .post .userleft .user-desc-block .forum a:visited {
  color: black;
  font-size: 0.8em;
}

.dark-mode ul.postslist .post .userleft .user-desc-block .forum a,
.dark-mode ul.postslist .post .userleft .user-desc-block .forum a:visited {
  color: #e0e3ea;
}

ul.postslist .post .userleft .user-desc-block .date {
  font-size: 0.8em;
  color: rgb(89, 89, 89);

  white-space: nowrap;
}

ul.postslist .post .userleft .user-desc-block .private {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8em;
  color: #344feb;
}

ul.postslist .post .userleft .user-desc-block .private a,
ul.postslist .post .userleft .user-desc-block .private a:visited {
  color: #344feb;
}

ul.postslist .post .userleft .user-desc-block .private .private-text-container {
  display: inline-grid;
}

ul.postslist .post .userleft .user-desc-block .private .private-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

ul.postslist .post .userleft .user-desc-block .private .private-user {
  display: flex;
  flex-direction: row;
  margin-left: 4px;
}

ul.postslist
  .post
  .userleft
  .user-desc-block
  .private
  .userReplyName-container {
  display: inline-grid;
}

ul.postslist .post .userleft .user-desc-block .private .userReplyName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

ul.postslist .post .userleft .usertitle {
  font-size: 13px;
  line-height: 18px;
  color: #595959;
  display: inline-grid;
}

ul.postslist .post .userleft .usertitle span {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.dark-mode ul.postslist .post .userleft .usertitle {
  color: #aaa;
}

ul.postslist .post .userleft .author {
  font-size: 13px;
  line-height: 18px;
  color: #595959;
  display: inline-grid;
}

ul.postslist .post .userleft .author span {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.dark-mode ul.postslist .post .userleft .author {
  color: #aaa;
}

ul.postslist .post .userleft .avatar {
  text-align: center;
  margin-right: 12px;
}

ul.postslist .post .userleft .avatar img {
  height: 42px;
  width: 42px;
  object-fit: cover;
  background: initial;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;
}

ul.postslist .post .postidshow {
  margin-left: 20px;
  margin-right: 3px;
  font-size: 1.2em;
  display: flex;
  align-items: center;
}

ul.postslist .post .textcenter {
  width: 100%;
  display: flex;
  flex-direction: column;

  position: relative;
  max-width: 732px;
}

ul.postslist .post .mine {
  font-size: 0.75em;
  vertical-align: super;
  margin-right: 3px;
  color: #31c48d;
}

.dark-mode ul.postslist .post .mine {
  color: #1eb32b;
}

.postsDisplay .replyForm {
  max-width: 732px;
  margin-left: auto;
  margin-right: auto;
}

.replyForm .replyToPost {
  padding: 15px 20px 0px;
  box-sizing: border-box;
}

ul.postslist .replyToPost {
  padding: 4px 16px 0px;
  box-sizing: border-box;
}

ul.postslist .replyToPost a {
  display: flex;
  flex-direction: row;
}

.replyForm .replyToPost .userReplyImg img {
  height: 28px;
  width: 28px;
  object-fit: cover;
  background: initial;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;
}

ul.postslist .replyToPost .userReplyImg img {
  height: 28px;
  width: 28px;
  object-fit: cover;
  background: initial;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;
}

.replyForm .replyToPost .userReplyImg {
  border: 1px solid #31c48d;
  border-radius: 20px;
  padding: 4px 10px 4px 8px;
  margin: 0px 7px;
}

ul.postslist .replyToPost .userReplyImg {
  border-radius: 20px;
  padding: 4px 10px 4px 2px;
  margin: 0px 7px;
  color: #664;
}
.dark-mode ul.postslist .replyToPost .userReplyImg {
  color: #aaa;
}

.replyForm .replyToPost .userReplyName {
  margin-left: 5px;
}

ul.postslist .replyToPost .userReplyName {
  margin-left: 5px;
}

.replyForm .replyToPost .removePostReply {
  cursor: pointer;
}

ul.postslist .replyToPost .removePostReply {
  cursor: pointer;
}

.replyForm .replyToPost .replySymbol {
  margin-right: 6px;
  color: #664;
}
.dark-mode .replyForm .replyToPost .replySymbol {
  color: #aaa;
}

ul.postslist .replyToPost .replySymbol {
  margin-right: 6px;
  color: #664;
}
.dark-mode ul.postslist .replyToPost .replySymbol {
  color: #aaa;
}

ul.postslist .replyToPost .textReply {
  color: #664;
}
.dark-mode ul.postslist .replyToPost .textReply {
  color: #aaa;
}

ul.postslist .post .textcenter .postTitle {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 10px;

  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  word-wrap: break-word;
  display: flex;
}

ul.postslist .post .textcenter .postTitle .postTitleLink {
  flex-grow: 2;
}

ul.postslist .post .textcenter .postTitle .similarityValue {
  background-color: rgba(49, 196, 141, 0.8);
  color: white;
  padding: 0px 8px;
  border-radius: 5px;
  font-size: 0.7em;
  font-weight: bold;
  max-height: 2.7em;
  align-content: center;
}

.similarPostsTitle {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

ul.postslist .post .textcenter .visibleStatus {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 4px;

  font-size: 0.9em;
  font-style: italic;
  color: red;
}

ul.postslist .post .textcenter .postTitle.replyTitle {
  font-size: 1.28em;
}

ul.postslist .post .textcenter .postTitle .replyIcon {
  margin-right: 8px;
}

ul.postslist .post .textcenter .postTitle a {
  color: #000;
}

ul.postslist .post .textcenter .postTitle a:visited {
  color: #000;
}

ul.postslist .post .downcontrols,
ul.postslist .post .downcontrols a,
ul.postslist .post .downcontrols a:visited {
  color: #595959;
}

ul.postslist .post .downcontrols .rating {
  display: block;
  cursor: pointer;
}

ul.postslist .post .downcontrols .rating.pos {
  color: #31c48d;
}

.dark-mode ul.postslist .post .downcontrols .rating.pos {
  color: #1eb32b;
}

ul.postslist .post .downcontrols .rating.neg {
  color: #ed3b50;
}

ul.postslist .post .downcontrols .rating:hover {
  color: #31c48d;
}

.dark-mode ul.postslist .post .downcontrols .rating:hover {
  color: #1eb32b;
}

ul.postslist .post .downcontrols .dislike.rating:hover {
  color: #ed3b50;
}

ul.postslist .post .downcontrols .like.rating svg {
  padding-right: 7px;
}

ul.postslist .post .downcontrols .dislike.rating svg {
  padding-left: 7px;
}

ul.postslist .post .downcontrols .clickButton {
  margin: 0px;
  padding: 10px 14px;
}

ul.postslist .post .textcenter .pagetext {
  display: block;
  flex-grow: 2;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow-wrap: anywhere;

  font-size: 17px;
  line-height: 26px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

ul.postslist .post .textcenter .pagetext b {
  font-family: "Roboto Regular", sans-serif;
}

ul.postslist .post .textcenter .pagetext.oldformat {
  padding-left: 16px;
  padding-right: 16px;
}

ul.postslist .post .textcenter .pagetext .block.text {
  padding-left: 16px;
  padding-right: 16px;
}

ul.postslist .post .textcenter .pagetext .block.text:nth-child(n + 2) {
  margin-top: 8px;
}

ul.postslist .post .textcenter .pagetextedit {
  display: block;
  flex-grow: 2;
  margin: 12px;
  padding: 10px;
  font-size: 1.4em;
}

ul.postslist .post .textcenter .pageoriginal {
  display: none;
}

ul.postslist .post .downcontrols {
  display: flex;
  flex-direction: row;
  padding: 5px 15px 5px 5px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  border-top: 1px solid #eee;
}

ul.postslist .post .downcontrols .leftstats {
  flex-grow: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

ul.postslist .post .downcontrols .controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: end;
}

ul.postslist .post .downcontrols .control {
  margin: 15px 14px;
  font-size: 1.1em;
  font-weight: 500;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  transition: 0.3s;
}

ul.postslist .post .downcontrols .controls .answer-icon {
  display: none;
}

@media only screen and (max-width: 798px) {
  ul.postslist .post .downcontrols .control.more-hide {
    display: none;
  }

  ul.postslist .post .downcontrols .controls .answer-icon {
    display: block;
  }

  ul.postslist .post .downcontrols .controls .answer-text {
    display: none;
  }
}

@media only screen and (max-width: 410px) {
  ul.postslist .post .downcontrols {
    padding-left: 10px;
    padding-right: 10px;
  }

  ul.postslist .post .downcontrols .control {
    margin: 15px 10px;
    font-size: 1em;
  }
}

@media only screen and (max-width: 359px) {
  ul.postslist .post .downcontrols {
    padding-left: 12px;
  }

  ul.postslist .post .downcontrols .clickButton {
    padding: 8px 12px;
  }

  ul.postslist .post .downcontrols .control {
    margin: 15px 7px;
    font-size: 1em;
  }

  ul.postslist .post .downcontrols .like.rating svg {
    padding-right: 6px !important;
  }
  ul.postslist .post .downcontrols .dislike.rating svg {
    padding-left: 6px !important;
  }
  ul.postslist .post .downcontrols .replies span {
    margin-left: 6px !important;
  }
  ul.postslist .post .downcontrols .views span {
    margin-left: 6px !important;
  }

  ul.postslist .post .downcontrols .control.more {
    margin-left: 4px;
    margin-right: 6px;
  }
}

@media only screen and (max-width: 329px) {
  ul.postslist .post .downcontrols {
    padding-right: 3px;
  }
}

ul.postslist .post .bottomStats {
  display: flex;
  flex-direction: row;
}

ul.postslist .post .downcontrols .postSubscribe.unsubscribe {
  color: #4287f5;
}

ul.postslist .post .downcontrols .threadSubscribe.unsubscribe {
  color: #f57542;
}

ul.postslist .post .downcontrols .bookmarkPost.unbookmark {
  color: #f57542;
}

ul.postslist .post .downcontrols .replies .newcomments {
  margin-left: 5px;
  font-size: 0.8em;
  color: #4287f5;
}

ul.postslist .post .downcontrols .replies span {
  margin-left: 6px;
}

ul.postslist .post .downcontrols .views span {
  margin-left: 6px;
}

/* sticky post */

ul.postslist .post.sticky {
  /* border-radius: initial; */
  background: initial;
}

ul.postslist .post.sticky .userleft {
  padding: 5px;
}

ul.postslist .post.sticky .userleft .usertitle {
  display: none;
}

ul.postslist .post.sticky .textcenter .postTitle {
  margin-top: 2px;
  font-size: 1.05em;
}

ul.postslist .post.sticky .textcenter .postTitle.replyTitle {
  font-size: 1.05em;
}

ul.postslist .post.sticky .textcenter .pagetext {
  padding-top: 6px;
  padding-bottom: 10px;
}

ul.postslist .post.sticky {
  margin-top: 7px;
  margin-bottom: 7px;
}

ul.postslist .post.sticky .userleft .avatar img {
  height: 32px;
  width: 32px;
  object-fit: cover;
}

.message-quote {
  border-left: 5px solid rgb(251, 209, 17);
  margin: 8px 4px 7px;
  border-radius: 5px;
}

.message-quote .message-quote-deep {
  background-color: rgba(251, 209, 17, 0.3);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  padding: 10px 16px;
}

.dark-mode .message-quote {
  border-left: 5px solid rgb(94, 86, 17);
}

.dark-mode .message-quote .message-quote-deep {
  background-color: rgba(94, 86, 17, 0.3);
}

.message-quote .message-quote-to {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  color: black;
}

.message-quote .message-quote-to img {
  height: 24px !important;
  width: 24px !important;
  object-fit: cover;
  background: initial;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;
  margin-right: 5px;
}

.message-quote .message-quote-to .username {
  font-weight: 500;
}

.ask-user {
  margin: 0px 2px 0px 2px;
  display: inline-flex;
  flex-direction: row;
  align-items: baseline;
}

.ask-user .username {
  font-weight: 500;
  color: #1d7a58;
}

.ask-user .avatar {
  display: flex;
  max-height: 19px;
  flex-direction: row-reverse;
  margin-right: 4px;
  align-self: center;
}

.ask-user .avatar img {
  height: 19px !important;
  width: 19px !important;
  object-fit: cover;
  background: initial;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;
}

ul.postslist .post .textcenter .pagetext .message-spoiler {
  display: inline-block;
  border: 1px solid rgb(200, 200, 200);
  padding: 2px 10px 2px 10px;
  margin: 18px 2px 6px 2px;
  background-color: rgb(252, 252, 252);

  -moz-box-shadow: -2px 2px 2px rgb(248, 248, 248);
  -webkit-box-shadow: -2px 2px 2px rgb(248, 248, 248);
  box-shadow: -2px 2px 2px rgb(248, 248, 248);
}

.message-spoiler .message-spoiler-description:after {
  content: attr(data-content);
}

.message-spoiler .message-spoiler-description {
  cursor: pointer;
}

.message-spoiler .message-spoiler-text {
  display: none;
  margin-top: 10px;
}

/* Userpage */

.userpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.userpage .userheader {
  max-width: 700px;
  width: 100%;
}

.userpage .userinfo {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 8px;
  margin: 20px 0px;
  padding: 0px 0px 15px;
  color: #595959;
  overflow: hidden;
}

.userpage .userinfo .customback {
  width: 100%;
}

.userpage .userinfo .customback img {
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.userpage .userinfo .avatar {
  display: block;
}

.userpage .userinfo .avatar img {
  border-radius: 50%;
  margin-top: -50%;
  background-color: white;
  padding: 4px;
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.userpage .userinfo .registation {
  margin: 14px 0px 0px;
  font-size: 0.9em;
}

.userpage .userinfo .lastactivity {
  margin: 14px 0px 14px;
  font-size: 0.9em;
}

.userpage .userinfo .username {
  font-size: 1.9em;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0px 15px;
}

.userpage .userinfo .usertitle {
  font-size: 1em;
  padding: 0px 15px;
}

.userpage .userinfo .contester {
  margin: 5px 0px 8px;
  padding: 10px;
  border: 1px solid #31c48d;
  border-radius: 20px;
  color: #31c48d;
}

.userpage .userinfo .join {
  font-size: 1em;
  padding: 0px 15px;
}

.userpage .userinfo .registation {
  padding: 0px 15px;
}

.userpage .userinfo .lastactivity {
  padding: 0px 15px;
}

.userpage .userinfo .infoblock {
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.userpage .userinfo .infoblock .info-element {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}

.userpage .userinfo .infoblock .info-element .value {
  font-size: 1.2em;
  font-weight: 1000;
  color: #1b1e24;
  padding: 6px;
}

.userpage .userinfo .infoblock .info-element .desc {
  font-size: 0.9em;
  color: #70737b;
}

.userpage .userinfo .infoblock .info-element.karma .value {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.userpage .karmaButton {
  font-size: 1.2em;
  font-weight: 500;
  font-variant-numeric: tabular-nums;
  color: #595959;
  cursor: pointer;
  transition: 0.3s;
  margin: 0px 8px;
}

.userpage .karmaButton.up:hover {
  color: #31c48d !important;
}

.userpage .karmaButton.up.chosen {
  color: #31c48d !important;
}

.dark-mode .userpage .karmaButton.up:hover {
  color: #1eb32b !important;
}

.dark-mode .userpage .karmaButton.up.chosen {
  color: #1eb32b !important;
}

.userpage .karmaButton.down:hover {
  color: #ed3b50 !important;
}

.userpage .karmaButton.down.chosen {
  color: #ed3b50 !important;
}

.userpage .editorBlock {
  padding: 10px;
  margin: 10px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
}

.userpage .editorBlock > * {
  padding: 5px 0px;
  text-align: center;
}

.split {
  border-color: rgba(0, 0, 0, 0.085);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: 96%;
  margin: 10px 20px;
}

/* Login */

.login-container {
  position: absolute;
  top: 70px;
  right: 10px;
  background: #31c48d;
  padding: 6px 20px 20px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 390px;
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (max-width: 420px) {
  .login-container {
    right: 0px;
  }
}

.login-container .login-spinner {
  margin-left: 10px;
  margin-top: 4px;
  color: white;
}

.login-container input[type="text"],
.login-container input[type="password"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.login-container .input-wrapper {
  position: relative;
  width: 100%;
  display: block;
}

.login-container .validation-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-70%);
  font-size: 15px;
}

.login-container .validation-icon.valid {
  color: #4caf50; /* Green for valid */
}

.login-container .validation-icon.invalid {
  color: #f44336; /* Red for invalid */
}

.login-container .closePanel {
  width: 100%;
  text-align: right;
}

.login-container .closeButton {
  color: white;
  cursor: pointer;
  font-size: 1.35em;
  padding: 3px 0px 5px;
}

.login-container .loginButtonRow {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
}

.login-container .loginButtonRow button {
  flex-grow: 2;
}

.login-container .loginButtonRow .googleLogin {
  padding: 10px;
  background: #1d7a58;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  min-width: 17px;
}

.login-container .loginButtonRow .facebookLogin {
  padding: 10px;
  background: #1d7a58;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  min-width: 17px;
}

.login-container .loginButtonRow .vkLogin {
  padding: 10px;
  background: #1d7a58;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  min-width: 17px;
}

.login-container button {
  width: 100%;
  padding: 10px;
  background: #1d7a58;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
}

.login-container .restore {
  text-align: center;
  margin-top: 10px;
  color: white;
}

.login-container .doingRegistation {
  color: white;
  text-align: center;
}

.why-login-containter {
  color: white;
  margin-top: 30px;
  margin-left: -20px;
  position: absolute;

  box-sizing: border-box;

  box-sizing: border-box;
  background: #31c48d;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: "Roboto";
  font-size: 0.88em;
  width: 100%;
}

/* error message */

.error-container {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  display: none;
}

.error-container.show-container {
  display: block;
}

.error-title {
  display: none;
}

.error-message {
  max-width: 500px;
  width: fit-content;

  background-color: white;
  padding: 15px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;

  animation: slideUpAndFadeOut 8s ease-out forwards;
}

.dark-mode .error-message {
  background-color: rgb(38, 43, 48);
  border: 1px solid rgba(0, 0, 0, 0.7);
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.5);
}

@keyframes slideUpAndFadeOut {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  5% {
    opacity: 1;
    transform: translateY(0%);
  }
  94% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.error-icon {
  margin-right: 10px;
  font-size: 1.4em;
}

.error-desc {
  margin: 0px;
}

.error-message.status-ok .error-icon {
  color: rgb(49, 196, 141);
}
.error-message.status-error .error-icon {
  color: rgb(237, 59, 80);
}
.error-message.status-warn .error-icon {
  color: rgb(196, 174, 49);
}

/* confirm message */

.confirm-container {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);

  max-width: 500px;
  box-sizing: border-box;

  /* border: 2px solid #e74c3c; */
  padding: 10px;
  text-align: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18);

  display: none;
  transition: 0.4s;
  font-size: 0.8em;
}

.confirm-container button {
  border: 0px;
}

.confirm-container .confirm-no {
  background-color: #f50253 !important;
}

.dark-mode .confirm-container .confirm-no {
  background-color: #831919 !important;
}

/* reply */

.clickButton {
  margin: 8px;
  padding: 14px;
  background-color: #31c48d;
  border-radius: 25px;
  margin-right: 20px;
  position: relative;
  z-index: 5;
  color: white;
  text-align: center;
  cursor: pointer;
  width: min-content;
  text-wrap: nowrap;
}

.replyForm {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

/* userslist */

.userslist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  list-style: none;
  margin: 0px;
  padding: 0px;
  padding: 30px;
  box-sizing: border-box;
}

.userslist .userBlock {
  display: flex;
  flex-direction: column;
  width: 250px;
  padding: 15px;
  margin: 10px;
  background: white;
  border-radius: 8px;
  align-items: center;
}

.userslist .userBlock .username {
  margin-bottom: 5px;
  font-size: 1.2em;
  text-align: center;
}

.userslist .userBlock .username a {
  color: black;
}

.userslist .userBlock .registation {
  font-size: 0.88em;
  color: #777;
  padding: 0px 20px;
  text-align: center;
}

.userslist .userBlock .lastactivity {
  font-size: 0.88em;
  color: #777;
  margin: 10px 0px 5px;
  padding: 0px 20px;
  text-align: center;
}

.userslist .userBlock .avatar {
  padding: 6px;
  margin: 5px;
  border: 1px solid #dcdcdc;
  min-height: 100px;
  margin-bottom: 20px;
}

.userslist .userBlock .avatar img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.userslist .userBlock .infoblock {
  margin: 5px 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.userslist .userBlock .infoblock .info-element {
  margin: 5px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}

.userslist .userBlock .infoblock .info-element .value {
  font-size: 1.2em;
  font-weight: 1000;
  color: #1b1e24;
  padding: 6px;
}

.userslist .userBlock .infoblock .info-element .desc {
  font-size: 0.9em;
  color: #70737b;
}

.userslist .userBlock .infoblock .info-element.karma .value {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userslist .userBlock .infoblock .info-element.karma .value svg {
  margin: 0px 5px;
}

.userslist .userBlock .infoblock .info-element.subscribers .value svg {
  margin: 0px 5px;
}

/* Mobile */

@media only screen and (min-width: 711px) {
  ul.postslist .post .textcenter .pagetext .block:not(.text) {
    padding-left: 16px;
    padding-right: 16px;
  }

  ul.postslist .post .like-dislike-disable {
    display: none;
  }
}

@media only screen and (max-width: 710px) {
  .mobile {
    display: flex !important;
  }

  .desktop {
    display: none !important;
  }

  .typicalBlock {
    border-radius: 0px;
  }

  ul.postslist {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  ul.postslist .post {
    flex-direction: column;
    margin-right: 0px;
    margin-left: 0px;
    border-right: 0px;
    border-left: 0px;
    border-radius: 0px;
  }

  ul.postslist .post .textcenter .pagetext .block.image {
    text-align: center;
  }

  ul.postslist .post .userleft .username {
    display: inline-grid;
  }

  ul.postslist .post .userleft .username a {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  ul.postslist .post .postidshow {
    margin-right: 4px;
  }

  ul.postslist .ratingCenterChanger {
    flex-grow: 2;
    justify-content: end;
    margin-left: 15px;
  }

  ul.postslist .post .textcenter {
    border-left: none;
  }

  ul.postslist {
    margin: 0px 0px 10px 0px;
    padding: 10px 0px;
  }

  .leftPostFollower {
    display: none !important;
  }

  .ratingLeftChanger {
    display: none !important;
  }

  .ratingCenterChanger {
    display: flex !important;
  }

  ul.postslist .ratingCenterChanger .rating {
    font-size: 1.4em;
  }
  ul.postslist .ratingCenterChanger svg {
    height: 13px;
    width: 16px;
  }

  ul.postslist .ratingCenterChanger {
    margin-left: 0px;
    padding: 2px 0px 3px 5px;
  }

  ul.postslist .ratingCenterChanger .up {
    margin-right: 7px;
  }

  ul.postslist .ratingCenterChanger .down {
    margin-left: 7px;
  }

  ul.postslist .ratingCenterChanger > div {
    padding: 7px 8px;
  }

  .userpage .userinfo {
    border-radius: 0px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
.captchaText {
  color: white;
  text-align: center;
  padding: 10px 2px;
  font-size: 0.7em;
}
.captchaText a {
  color: #ccc;
}

.threadInputName {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 18px 5px;
  box-sizing: border-box;

  max-width: 800px;
}

.threadInputName input {
  background: rgba(0, 0, 0, 0);
  flex-grow: 2;
  font-size: 1.5em;
  border: 0px;
  width: 100%;
}

.threadInputName input:focus {
  outline: none;
}

.tagsInput {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px 18px 5px;
  box-sizing: border-box;

  max-width: 800px;
}

.post .tagsInput {
  padding-top: 20px;
}

.tagsInput input {
  background: rgba(0, 0, 0, 0);
  flex-grow: 2;
  font-size: 0.9em;
  border: 0px;
}

.tagsInput input:focus {
  outline: none;
}

.tagsInput .tagsAiButton {
  color: #595959;
  font-size: 1.3em;
  cursor: pointer;
}

.saveDate {
  background: white;
  padding: 10px 0px 40px;
  width: 100%;
  text-align: center;
  border-radius: 8px;
}

.dark-mode .saveDate {
  background: rgb(38, 43, 48);
  color: #dbdbdb;
}

@media only screen and (max-width: 1400px) {
  .userpage .userheader {
    width: 60%;
  }
}

@media only screen and (max-width: 1210px) {
  .userpage .userheader {
    width: 70%;
  }
}

@media only screen and (max-width: 920px) {
  .userpage .userheader {
    width: 85%;
  }
}

@media only screen and (max-width: 700px) {
  .userpage .userheader {
    width: 100%;
  }
}

@media only screen and (max-width: 1910px) {
  header .socialNetworks {
    display: none;
  }
}

@media only screen and (max-width: 1732px) {
  header .loginButton span {
    display: none;
  }

  header .loginInfo {
    margin-left: 20px;
  }

  header .loginButton svg {
    font-size: 1.8em;
  }

  header .addPost {
    margin-left: 2px;
  }

  header .username {
    display: none !important;
  }
}

@media only screen and (max-width: 1348px) {
  header nav.mainMenu {
    flex-grow: 1000;
  }

  header nav.mainMenu .optional {
    display: none;
  }

  header .menu {
    display: block;
  }
  header nav.leftMenu .onlyLeft {
    display: block;
  }
  header > a {
    flex-grow: 2;
  }
  header .languageSwitcher {
    display: none;
  }
}
@media only screen and (max-width: 920px) {
  header nav.mainMenu {
    display: none;
  }
}

@media only screen and (max-width: 534px) {
  header > a {
    width: 110px;
  }

  header .loginInfo {
    margin-left: 0px;
  }

  header .addPost {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 378px) {
  header .addPost {
    margin-right: 2px;
  }
}

@media only screen and (min-width: 371px) and (max-width: 410px) {
  header .avatar {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  header .loginInfo {
    margin-left: 4px;
  }

  header .searchPanel {
    margin-left: 4px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 370px) {
  header > a {
    display: none;
  }

  header {
    justify-content: center;
  }

  header .menu {
    flex-grow: 2;
  }
}

@media only screen and (max-width: 320px) {
  header > a {
    display: none;
  }

  header {
    justify-content: center;
  }

  header .menu {
    flex-grow: 2;
  }
}

@media only screen and (max-width: 570px) {
  .mainTitle .titleText {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 900px) {
  .recentPosts .postslist {
    margin-left: 0%;
    margin-right: 0%;
    min-width: initial;
  }
}

/* Article */
.article .block img {
  max-width: 100%;
  height: auto;
}

.article .block p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.article .block ul {
  list-style: none;
}

.article .block li {
  margin: 8px 0 8px 4px;
  position: relative;
}

.article .block li:before {
  display: block;
  content: "";
  position: absolute;
  top: 10px;
  left: -20px;
  width: 6px;
  height: 6px;
  background-color: currentColor;
  border-radius: 100%;
}

.article .block.text .ask-user {
  cursor: pointer;
}

.article .block .imageContainer {
  margin: 15px 0px;
}

.article .block .imageContainer .imageDescription {
  font-size: 0.88em;
}

@media only screen and (min-width: 1300px) {
  .article .block img {
    max-width: 700px;
  }

  .post.tree .article .block img {
    max-width: 100%;
  }
}

.article .block .imageContainer .zoomImage {
  cursor: pointer;
}

.article .block .imageContainer.zoomed .zoomImage {
  position: fixed;
  top: 0;
  left: 0;
  max-width: inherit;
  margin: 0px;
  z-index: 22;

  /* Fix selection on image switch */
  user-select: none;
}

.article .block .imageContainer .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  z-index: 21;
}

.article .block .imageContainer.zoomed .overlay {
  display: block;
}

.article .block .imageContainer .nextPicture,
.article .block .imageContainer .prevPicture {
  position: fixed;
  top: calc(50% - 24px);
  background: black;
  color: white;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);

  z-index: 22;
  display: none;
  opacity: 0.7;
}
.article .block .imageContainer .nextPicture {
  right: 10px;
}

.article .block .imageContainer .prevPicture {
  left: 10px;
}

.article .block .imageContainer.zoomed .nextPicture,
.article .block .imageContainer.zoomed .prevPicture {
  display: flex;
}

.article .block.spoiler:not(.visible) {
  position: relative;
}
.article .block.spoiler:not(.visible) .blockcontent {
  filter: blur(10px);
  pointer-events: none;
}

.article .block.adult:not(.visible) {
  position: relative;
}
.article .block.adult:not(.visible) .blockcontent {
  filter: blur(24px);
  pointer-events: none;
}

.article.hideAdult .blockcontent {
  pointer-events: none;
}

.article .blockAdultButton,
.article .blockAdultSpoilerButton {
  background: #eb4034;
  padding: 2px 8px;
  border-radius: 17px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: white;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  text-wrap: nowrap;
}

.article .blockSpoilerButton {
  background: #eba234;
  padding: 2px 8px;
  border-radius: 17px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: white;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  text-wrap: nowrap;
}

.article.collapsed {
  position: relative;

  max-height: 1000px;
  overflow: hidden;
}

.post.preview .article.collapsed {
  max-height: 500px;
}

.article .cut-off {
  content: "";
  width: 100%;
  height: 200px;
  background: linear-gradient(to bottom, transparent, white);
  display: block;

  position: absolute;
  bottom: 0px;
}

.article .showFullPost {
  background: #344feb;
  padding: 6px 10px;
  border-radius: 17px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: white;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.article .showFullPost.collapse {
  position: sticky;
  width: fit-content;
  bottom: 10px;
  transform: translate(-50%, 0);
}

@media only screen and (min-width: 710px) and (max-width: 1200px) {
  .article .showFullPost.collapse {
    transform: translate(0px, -10px);
  }
}

.post-quote {
  border-left: 5px solid rgba(49, 196, 142, 1);
  margin: 8px 20px 0px;
  border-radius: 5px;
}

.post-quote .no-text {
  margin: 10px 12px;
}

.post-quote-deep {
  background-color: rgba(49, 196, 142, 0.3);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dark-mode .post-quote {
  border-left-color: rgba(17, 94, 23, 1);
}

.dark-mode .post-quote-deep {
  background-color: rgba(17, 94, 23, 0.3);
}

.post-quote .userReplyImg img {
  height: 24px;
  width: 24px;
  object-fit: cover;
  background: initial;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;

  margin-right: 5px;
}

.post-quote .userReplyImg .userReplyName {
  font-weight: 500;
}

.post-quote .userReplyImg {
  padding: 10px 5px 0px 8px;
  margin: 0px 7px;
  color: black;
}

.dark-mode .post-quote .userReplyImg {
  color: #aaa;
}

ul.postslist .replyToPost .userReplyImg {
  border-radius: 20px;
  padding: 4px 10px 4px 2px;
  margin: 0px 7px;
}
.dark-mode ul.postslist .replyToPost .userReplyImg {
  color: #aaa;
}

ul.postslist .post .textcenter .pagetext.quote {
  padding-top: 10px;
}

/* Editor */

.blocks-editor {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  padding: 10px 18px;
  width: 100%;
}

.blocks-editor .block {
  display: flex;
  flex-direction: column;
}

.blocks-editor .block .block-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.blocks-editor .block .block-content {
  flex-grow: 2;
}

.blocks-editor .block .block-controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 0px 15px;
  width: 100%;
  flex-wrap: wrap;
}

.blocks-editor .block .block-controls > * {
  padding: 0px 10px;
  margin: 0px 12px;
  font-size: 1.5em;
  font-weight: 500;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  color: #595959;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 7px;
}

.blocks-editor .block-text-controls {
  position: -webkit-sticky;
  position: sticky;
  top: var(--header-top-size);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;

  background-color: white;
  padding: 6px 0;
  /* box-shadow: 0 2px 2px -2px rgba(34,47,62,.1), 0 8px 8px -4px rgba(34,47,62,.07); */
  border-bottom: 1px solid #dcdcdc;
  z-index: 6; /* for smile z-index */
}

.editor .blocks-editor .block-text-controls {
  top: 0px;
}

.blocks-editor .block-text-controls button {
  border: 1px solid #cecece;
  background-color: #fff;
  color: black;
  padding: 8px;
  border-radius: 0px;
  row-gap: 5px;
  column-gap: 5px;
  transition: 0.4s;
  cursor: pointer;
  min-width: 35px;
}

.blocks-editor .block-text-controls button:hover {
  background-color: #dedede;
  color: #444;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

@media (hover: none) and (pointer: coarse) {
  .blocks-editor .block-text-controls button.auto:hover {
    /* Disable hover styles */
    background-color: inherit;
    color: inherit;
    box-shadow: inherit;
  }
}

.blocks-editor .block-text-controls button.selected {
  background-color: #dedede !important;
  color: #444 !important;
}

.blocks-editor .block .block-controls svg {
  margin-left: 4px;
}

.blocks-editor .block .add-block-text-button {
  display: flex;
  flex-direction: row;
}

.blocks-editor .block .add-block-text-button svg {
  width: 25px;
}

.blocks-editor .block .block-text {
  display: flex;
}

.blocks-editor .block .checkbox {
  margin-bottom: 10px;
}

.blocks-editor .block .block-text .editor {
  flex: 1;
  margin-right: 10px;
  min-height: 120px;

  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;

  padding: 8px;
  margin: 0px 0px 0px;

  overflow-wrap: anywhere;
  outline: none;
}

.blocks-editor .block .block-editor.minimal .block-text .editor {
  border-top: 1px solid #dcdcdc;
  min-height: 18px;
}

.blocks-editor
  .block:nth-child(1)
  .block-editor.minimal
  .block-text
  .editor:empty:not(:focus):before {
  content: attr(data-hint-minimal);
  color: grey;
  font-style: italic;
  pointer-events: none;
}

.blocks-editor
  .block:nth-child(1)
  .block-editor:not(.minimal)
  .block-text
  .editor:empty:not(:focus):before {
  content: attr(data-hint);
  color: grey;
  font-style: italic;
  pointer-events: none;
}

.blocks-editor .block .block-editor .userNameList {
  list-style: none;
  background: white;
  padding: 5px 0px 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.dark-mode .blocks-editor .block .block-editor .userNameList {
  background: rgb(38, 43, 48);
}

.blocks-editor .block .block-editor .userNameList li {
  cursor: pointer;
  font-size: 0.95em;
  /* flex-grow: 1; */
  text-align: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 8px;
  border: 1px solid #31c48d;
  border-radius: 40px;
  margin: 5px 5px 0px 0px;
}

.dark-mode .blocks-editor .block .block-editor .userNameList li {
  border-color: #115e17;
}

.blocks-editor .block .block-editor .userNameList li .username {
  margin-left: 5px;
}

.blocks-editor .block .block-editor .userNameList li .avatar img {
  height: 27px;
  width: 27px;
  object-fit: cover;
  background: initial;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;
}

.blocks-editor .block .block-video {
  display: flex;
  flex-direction: row;
}

.blocks-editor .block .block-video .enterLink {
  padding: 4px 0px 8px;
}

.blocks-editor .block .block-video input {
  margin-left: 10px;
  flex-grow: 2;
  padding: 5px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
}

.blocks-editor .block .block-order {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-right: 5px;

  font-size: 1.5em;
  font-weight: 500;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  color: #595959;
  cursor: pointer;
  transition: 0.3s;
}

.blocks-editor .block .block-order > div {
  padding: 7px 0px;
}

.blocks-editor .block .order-button {
  cursor: pointer;
}

.blocks-editor .block .settings-button.active {
  color: #4287f5;
}

.blocks-editor .block .block-image img {
  width: 100%;
}

.blocks-editor .block .imageUpload,
.blocks-editor .block .videoUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 120px;
  padding: 60px;

  background: #dbdbdb;
  border-radius: 7px;

  font-size: 1.45em;
  text-align: center;
  color: #70737b;

  margin-bottom: 6px;
}

.blocks-editor .block .videoUpload.locked {
  opacity: 0.6;
}

.blocks-editor .block .imageUpload > *,
.blocks-editor .block .videoUpload > * {
  padding: 15px 10px;
}

.blocks-editor .block .imageIcon,
.blocks-editor .block .videoIcon {
  font-size: 1.7em;
}

.blocks-editor .block .blocks-editor {
  display: flex;
  flex-direction: column;
}

.blocks-editor .block .blocks-text-controls {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 410px) {
  .blocks-editor .block .imageUpload {
    padding: 15px;
  }
}

.blocks-editor .block .smileChooseButton {
  position: relative;
}

.relativeContainer {
  position: absolute;
  top: calc(100% + 5px);
  left: 0px;
}

.blocks-editor .block .smileMenu {
  display: flex;
  width: max-content;
  max-width: 231px;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1.8em;
  list-style: none;
  margin: 0px;
  padding: 10px;

  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
}

.replyForm .blocks-editor {
  max-width: 800px;
}

.replyForm .reply-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.replyForm .reply-block .buttonRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.replyForm .reply-block .storageDate {
  margin-bottom: 10px;
  font-size: 0.89em;
  color: rgb(180, 180, 180);
}

.replyForm .reply-block .storageSwitcher {
  display: flex;
  flex-direction: row;
  padding: 10px 8px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 90%;
}

.replyForm .reply-block .storageSwitcher .storageSwitch {
  margin: 2px 8px;
  border: 1px solid #cecece;
  padding: 10px;
  min-width: 15px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.4s;
  background-color: #fff;
  color: #70737b;
}

.replyForm .reply-block .storageSwitcher .storageSwitch:hover {
  background-color: #dedede;
  color: #444;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

.replyForm .reply-block .storageSwitcher .storageSwitch.focus {
  background-color: #dedede;
  color: #444;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

.replyForm .reply-block .sendingData {
  color: #70737b;
  padding: 10px;
}

.replyForm .reply-block .controls {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 18px 10px;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
}

.replyForm .reply-block .controls > * {
  margin-bottom: 10px;
}

.post .controls > * {
  margin-bottom: 10px;
}

.tagSearchList {
  list-style: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  flex-wrap: wrap;
}

.tagSearchList li {
  cursor: pointer;
  padding: 5px 10px;
  border: 0px solid #eee;
}

.forumSearchList {
  list-style: none;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 800px;
  flex-wrap: wrap;
}

.forumSearchList li {
  cursor: pointer;
  padding: 10px 10px;
  border: 0px solid #eee;
  border-bottom: 1px solid #eee;
  color: #444;
}

.dark-mode .forumSearchList li {
  color: #e0e3ea;
}

.post .controls {
  padding: 6px 18px;
}

.post .downcontrols .controls {
  padding: 0px;
  row-gap: 10px;
  column-gap: 5px;
}

.animate-top {
  opacity: 0;
  transform: translateY(-100%);
  animation: slideFromTop 0.4s forwards;
}

@keyframes slideFromTop {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.youtube-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  margin: 12px 0px;
}

.youtube-container .youtube-play-background-button {
  width: 60px;
  height: 60px;
  position: absolute;
  margin-left: -30px;
  margin-top: -30px;
  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.38);
  border-radius: 50%;
  cursor: pointer;
}

.youtube-container .youtube-play-button {
  position: absolute;
  margin-left: -15px;
  margin-top: -15px;
  top: 50%;
  left: 50%;
  fill: rgba(255, 255, 255, 0.83);
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.youtube-container iframe,
.youtube-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtube-container img {
  cursor: pointer;
}

.vk-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  margin: 12px 0px;
}

.vk-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.twitch-container,
.coub-container,
.rutube-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  margin: 12px 0px;
}

.twitch-container iframe,
.coub-container iframe,
.rutube-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0px;
}

.video-container {
  width: 100%;
  overflow: hidden;
  margin: 12px 0px;
  position: relative;
}

.video-container video {
  width: 100%;
  height: auto;
  display: block;
}

.video-container video.animation {
  cursor: pointer;
}

.video-container .gif-sticker {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 5px;
  border-radius: 30%;
  width: 30px;
  text-align: center;
}

.video-container .gif-sticker.playing {
  display: none;
}

.video-container .video-preview-processing {
  width: fit-content;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.video-container .video-preview-processing img {
  filter: blur(2.5px);
  max-width: 100%;
}

.video-container .video-preview-processing .processing-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.25);
  font-size: 0.91em;
  padding: 5px 0px;
}

@media only screen and (min-width: 410px) {
  .video-container video {
    max-height: 500px;
  }

  .video-container .video-preview-processing img {
    max-height: 500px;
  }
}

.donateBlock,
.downloadBlock {
  padding: 15px;
  margin-left: 15px;
  text-align: center;
  color: #595959;
  margin-top: 10px;
}

.dark-mode .donateBlock,
.dark-mode .downloadBlock {
  color: #b2b4bb;
}

.linkLeftBlock {
  padding: 8px 5px 5px 20px;
  font-size: 0.85em;
  box-sizing: border-box;
}

.linkLeftBlock a {
  color: #aaa;
  padding: 0px 5px;
}

.linkLeftBlock a:visited {
  color: #aaa;
}

.donateBlock .description,
.downloadBlock .description {
  margin-bottom: 20px;
}

.donateBlock img,
.downloadBlock img {
  width: 40px;
  margin-right: 25px;
}

.resetViewHistory,
.showViewHistory {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  cursor: pointer;
  color: #70737b;
  display: flex;
  align-items: center;
}

.showViewHistory {
  margin-top: 5px;
}

.dark-mode .resetViewHistory,
.dark-mode .showViewHistory {
  color: #e0e3ea;
}

.resetViewHistory svg,
.showViewHistory svg {
  font-size: 1.2em;
  margin-right: 10px;
}

@media only screen and (min-width: 1210px) {
  .post .youtube-container {
    padding-bottom: 0px;
    text-align: center;
  }

  .post .youtube-container iframe,
  .post .youtube-container img {
    position: initial;
    width: 700px;
    height: 394px;
  }

  .post .vk-container {
    position: initial;
    padding-bottom: 0px;
    text-align: center;
  }

  .post .vk-container iframe {
    position: initial;
    width: 700px;
    height: 394px;
  }

  .post .twitch-container,
  .post .coub-container,
  .post .rutube-container {
    position: initial;
    padding-bottom: 0px;
    text-align: center;
  }

  .post .twitch-container iframe,
  .post .coub-container iframe,
  .post .rutube-container iframe {
    position: initial;
    width: 700px;
    height: 394px;
  }
}

@media only screen and (min-width: 1400px) {
  .mainTitle {
    min-width: 732px;
  }

  .mainTitle .titleText {
    justify-content: center;
    display: flex;
  }

  .mainTitle .direction {
    position: absolute;
    right: 12px;
  }
}

.typical-page {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.removeAccount {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.removeAccountText {
  margin: 30px 10px 10px;
}

.removeAccountButton {
  background-color: #f50253;
  border: 0px;
}

/* Error page */

.error-page {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-page img {
  max-width: 100%;
}

.error-page .notFoundText {
  font-weight: 500;
  font-size: 1.3em;
  color: rgba(0, 0, 0, 0.8);
  margin: 20px 10px;
  text-align: center;
}

/* Socials */

.userSocials {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0px 10px 8px;
  margin: 0px;
  flex-wrap: wrap;

  color: #777;
}

.userSocials svg {
  margin-right: 7px;
  height: 22px;
}

.userSocials li {
  padding: 5px 10px;
  display: flex;
  align-items: center;

  /* padding: 8px;
  border-radius: 20px;
  min-width: 50px;
  text-align: center;
  border: 1px solid #e61856; */
}

.userSocials a,
.userSocials a:visited {
  color: inherit;
}

.userSocials .telegram {
  color: #24a1de;
}

.userSocials .discord {
  color: #7289da;
}

.userSocials .homepage {
  color: #31c48d;
}

/* Overlay */
.topScrollButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: black;
  color: white;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  z-index: 8;
}

.topScrollButton.enabled {
  animation: fadeIn 0.6s forwards;
  display: flex;
}

.topScrollButton:not(.enabled) {
  animation: fadeOut 0.5s forwards;
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    display: none;
  }
  to {
    opacity: 1;
    display: flex;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    display: flex;
  }
  to {
    opacity: 0;
    display: none;
  }
}

/* Calendar */

.react-calendar-datetime-picker .header {
  background-color: #31c48d !important;
}

.dark-mode .react-calendar-datetime-picker .header {
  background-color: #115e17 !important;
}

.react-calendar-datetime-picker .daysList .is-selected-day {
  background-color: #31c48d !important;
}

.dark-mode .react-calendar-datetime-picker .daysList .is-selected-day {
  background-color: #115e17 !important;
}

.dark-mode .react-calendar-datetime-picker .calender-modal {
  background-color: rgb(38, 43, 48);
}

.dark-mode .react-calendar-datetime-picker .daysList .daysList-day {
  color: #888;
}

.dark-mode
  .react-calendar-datetime-picker
  .daysList
  .daysList-day.is-selected-day {
  color: white;
}

.dark-mode .react-calendar-datetime-picker .daysList .is-disabled {
  background: #161a1d;
}

.dark-mode .react-calendar-datetime-picker .time .time--input {
  background: rgb(38, 43, 48);
  color: #ccc;
}

.dark-mode
  .react-calendar-datetime-picker
  .time
  .time-fieldset
  .time-fieldset--dec,
.dark-mode .react-calendar-datetime-picker .time .time-fieldset {
  fill: white;
}

.react-calendar-datetime-picker .time .time-fieldset .time-fieldset--inc,
.react-calendar-datetime-picker .time .time-fieldset .time-fieldset--dec {
  width: 26px !important;
  height: 26px !important;
  background-color: inherit;
}

.react-calendar-datetime-picker .time .time-fieldset .time-fieldset--inc svg,
.react-calendar-datetime-picker .time .time-fieldset .time-fieldset--dec svg {
  transform: translate(-50%, -50%) scale(1) !important;
}

.react-calendar-datetime-picker .input-picker .input-picker--input {
  width: initial !important;
  height: initial !important;
  line-height: initial !important;
  border-radius: 0px !important;
  border-width: 0px !important;
  padding: 10px !important;
  border-bottom: 1px solid #ebebeb !important;
  font-size: 1.1em;
  color: rgba(0, 0, 0, 0.6);
}

.dark-mode .react-calendar-datetime-picker .input-picker .input-picker--input {
  color: #aaa;
  background-color: rgb(38, 43, 48);
}

.react-calendar-datetime-picker .calender-modal {
  bottom: 42px;
}

@media only screen and (max-width: 362px) {
  .react-calendar-datetime-picker .calender-modal {
    width: 100% !important;
    min-width: 152px;
  }
}

.keyboardHelp {
  margin-left: 15px;
  padding: 10px 5px;
  font-size: 0.9em;
}

.keyboardHelp .keyboardStruct {
  margin-bottom: 15px;
}

.keyboardHelp .keyboardStruct img {
  width: 140px;
}

.keyboardDesc {
  padding: 12px;
  margin-bottom: 20px;
}

/* Dark Mode */

.dark-mode .App {
  background: #161a1d;
  color: #e0e3ea;
}

.dark-mode .threadTitle,
.dark-mode .mainTitle {
  border-bottom-color: #161a1d;
}

.dark-mode header .logo {
  background-image: url("talkvio-logo_cut_black.png");
}

.dark-mode a:link {
  color: #1a9123;
}

.dark-mode a:visited {
  color: #1a9123;
}

.dark-mode header {
  background-color: #115e17;
  border-bottom: 0px;
}

.dark-mode select {
  background-color: #115e17;
}

.dark-mode .clickButton {
  background-color: #115e17;
  color: #e0e3ea;
}

.dark-mode .checkbox input:checked ~ .checkmark {
  background-color: #115e17 !important;
}

.dark-mode .login-container {
  background: #115e17;
}

.dark-mode .login-container input {
  color: #e0e3ea;
  background: rgb(38, 43, 48);
}

.dark-mode ::placeholder {
  color: #b9bcc4;
}

.dark-mode .why-login-containter {
  background: #115e17;
}

.dark-mode .replyForm .reply-block .storageSwitcher .storageSwitch {
  background: rgb(38, 43, 48);
  color: #e0e3ea;
}

.dark-mode .replyForm .reply-block .storageSwitcher .storageSwitch.focus {
  background: #b9bcc4;
}

.dark-mode .askRegistationBlock {
  background: rgb(38, 43, 48);
}

.dark-mode header .searchPanel {
  background: rgb(38, 43, 48);
}

.dark-mode header .searchPanel input {
  color: #e0e3ea;
}

.dark-mode .blocks-editor .block-text-controls button {
  background: rgb(38, 43, 48);
  color: #e0e3ea;
}

.dark-mode header nav.leftMenu,
.dark-mode header .rightMenu {
  background: rgb(38, 43, 48);
}

.dark-mode header nav.leftMenu a,
.dark-mode header .rightMenu a {
  color: #e0e3ea !important;
  border-bottom-color: #454545;
}

.dark-mode header nav.leftMenu a:visited,
.dark-mode header .rightMenu a:visited {
  color: #e0e3ea !important;
}

.dark-mode ul.postslist .post {
  background: rgb(38, 43, 48);
}

.dark-mode ul.postslist .post .userleft {
  border-right-color: #161a1d;
  border-bottom-color: #161a1d;
}

.dark-mode ul.postslist .post .downcontrols {
  border-top-color: #161a1d;
}

.dark-mode .editor {
  background: rgb(38, 43, 48) !important;
}

.dark-mode .replyForm .reply-block .controls {
  border-bottom-color: #161a1d;
  border-top-color: #161a1d;
}

.dark-mode .typicalBlock {
  background: rgb(38, 43, 48);
}

.dark-mode header .notificationList {
  background: rgb(38, 43, 48);
  color: #dbdbdb;
}

.dark-mode .blocks-editor .block .block-order {
  color: #dbdbdb;
}

.dark-mode header .notificationList .notification {
  border-bottom-color: #454545;
  color: #dbdbdb;
}

.dark-mode header .notificationList .notification {
  color: #dbdbdb !important;
}

.dark-mode header .notificationList .notification.readed {
  color: #7c7c7c !important;
}

.dark-mode .threadInputName input {
  color: #dbdbdb;
}

.dark-mode .blocks-editor .block .block-controls > *,
.dark-mode .blocks-editor .block .block-controls svg {
  color: #dbdbdb;
  fill: #dbdbdb;
}

.dark-mode ul.postslist .post .downcontrols,
.dark-mode ul.postslist .post .downcontrols a,
.dark-mode ul.postslist .post .downcontrols a:visited {
  color: #bfbfbf;
}

.dark-mode .userpage .userinfo,
.dark-mode .settings {
  background: rgb(38, 43, 48);
  color: #dbdbdb;
}

.dark-mode .userslist .userBlock {
  background: rgb(38, 43, 48);
}

.dark-mode .userslist .userBlock .avatar {
  border-color: #161a1d;
}

.dark-mode .userslist .userBlock .infoblock .info-element .value,
.dark-mode .userpage .userinfo .infoblock .info-element .value {
  color: #dbdbdb;
}

.dark-mode .userpage .userinfo .infoblock .info-element .value svg {
  color: #dbdbdb;
}

.dark-mode .text-input input {
  color: #dbdbdb;
}

.dark-mode .userslist .userBlock .username a {
  color: #dbdbdb;
}

.dark-mode a {
  color: #e0e3ea;
}

.dark-mode a:visited {
  color: #e0e3ea;
}

.dark-mode ul.postslist .post .textcenter .postTitle a,
.dark-mode ul.postslist .post .textcenter .postTitle a:visited {
  color: #e0e3ea !important;
}

.dark-mode ul.postslist .post .userleft .username a,
.dark-mode ul.postslist .post .userleft .username a:visited {
  color: #e0e3ea;
}

.dark-mode ul.postslist .ratingLeftChanger {
  border-color: rgb(43, 43, 43);
}

.dark-mode ul.postslist .post .textcenter .pagetext .block.text a,
.dark-mode ul.postslist .post .textcenter .pagetext .block.text a:visited {
  color: #1a9123;
}

.dark-mode .article .cut-off {
  background: linear-gradient(180deg, transparent, #000);
}

.dark-mode .tagsInput input {
  color: #dcdcdc;
}

.dark-mode .blocks-editor .block-text-controls {
  background: rgb(38, 43, 48) !important;
}

.mouseFocusContainer {
  position: relative;
}

.mouseFocusContainer:not(.active) .focusContainer {
  display: none;
  animation-name: fadeOut;
  animation-duration: 0.2s;
}

.mouseFocusContainer.active .focusContainer {
  display: flex;
  animation-name: fadeIn;
  animation-duration: 0.2s;
}

.userInfoPanel {
  padding: 15px;
  margin-left: 15px;
}

.userInfoPanel .userPanelAvatar {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.userInfoPanel .userPanelAvatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.userInfoPanel .userPanelInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userInfoPanel .username {
  font-size: 1.2em;
  font-weight: 500;
  color: #000;
  margin-bottom: 12px;
  text-decoration: none;
}

.dark-mode .userInfoPanel .username {
  color: #fff;
}

.userInfoPanel .stats {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 8px;
}

.userInfoPanel .statItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8px;
}

.userInfoPanel .statItem .value {
  font-weight: 600;
  font-size: 1.1em;
  color: #31c48d;
}

.dark-mode .userInfoPanel .statItem .value {
  color: #1a9123;
}

.userInfoPanel .statItem .label {
  font-size: 0.85em;
  color: #70737b;
  margin-top: 4px;
}

.dark-mode .userInfoPanel .statItem .label {
  color: #b9bcc4;
}

.userInfoPanel .userPanelNav {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.dark-mode .userInfoPanel .userPanelNav {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.userInfoPanel .navLink {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: #70737b;
  text-decoration: none;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.userInfoPanel .navLink:hover {
  background-color: rgba(49, 196, 141, 0.1);
  color: #31c48d;
}

.dark-mode .userInfoPanel .navLink {
  color: #b9bcc4;
}

.dark-mode .userInfoPanel .navLink:hover {
  background-color: rgba(17, 94, 23, 0.2);
  color: #1a9123;
}

.userInfoPanel .navLink svg {
  width: 16px;
  margin-right: 12px;
}

.userInfoPanel .navLink span {
  font-size: 0.9em;
  font-weight: 500;
}

.userInfoPanel .userPanelAvatar img.guest {
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.userInfoPanel .userPanelAvatar img.guest:hover {
  opacity: 1;
}

.userInfoPanel .username.guest {
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.userInfoPanel .username.guest:hover {
  opacity: 1;
  color: #31c48d;
}

.userInfoPanel .guestMessage {
  color: #70737b;
  font-size: 0.9em;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  background-color: rgba(49, 196, 141, 0.1);
  margin: 8px 0;
}

.dark-mode .userInfoPanel .guestMessage {
  color: #b9bcc4;
  background-color: rgba(17, 94, 23, 0.2);
}

.userInfoPanel .navLink.guest {
  opacity: 0.7;
}

.userInfoPanel .navLink.guest:hover {
  opacity: 1;
}

/* Highlight active nav link */
.userInfoPanel .navLink.active {
  background-color: rgba(49, 196, 141, 0.1);
  color: #31c48d;
}

.dark-mode .userInfoPanel .navLink.active {
  background-color: rgba(17, 94, 23, 0.2);
  color: #1a9123;
}

.userInfoPanel .navLink {
  position: relative;
  overflow: hidden;
}

.userInfoPanel .navLink.active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 60%;
  background-color: #31c48d;
  border-radius: 0 2px 2px 0;
}

.dark-mode .userInfoPanel .navLink.active::before {
  background-color: #1a9123;
}

.settings .keyboardHelp {
  padding: 15px;
  margin: 0px;
  border-radius: 6px;
}

.settings .keyboardHelp .keyboardStruct {
  margin-bottom: 15px;
}

.settings .keyboardHelp .keyboardStruct img {
  width: 140px;
}

.settings .keyboardHelp .keyboardDesc {
  padding: 12px;
  margin-bottom: 20px;
  background: transparent;
}

.settings .keyboardHelp .keyboardDesc div {
  margin: 8px 0;
}

.settings .keyboardHelp krb {
  display: inline-block;
  min-width: 25px;
  padding: 2px 6px;
  background: #31c48d;
  color: white;
  border-radius: 4px;
  text-align: center;
  margin-right: 8px;
  font-weight: 500;
}

.dark-mode .settings .keyboardHelp krb {
  background: #115e17;
}

/* Header highlight active menu */
header .mainMenu a.active {
  position: relative;
}

header .mainMenu a.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 2px;
  background-color: #fff;
  border-radius: 2px;
}

/* Left and Right Menu Active States */
header nav.leftMenu > a.active,
header .rightMenu > a.active {
  position: relative;
  background-color: rgba(49, 196, 141, 0.1);
  font-weight: 500;
}

header nav.leftMenu > a.active::before,
header .rightMenu > a.active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 60%;
  background-color: #31c48d;
  border-radius: 0 2px 2px 0;
}

header nav.leftMenu > a,
header .rightMenu > a {
  transition: all 0.2s ease;
  position: relative;
}

header nav.leftMenu > a:hover,
header .rightMenu > a:hover {
  background-color: rgba(49, 196, 141, 0.05);
}

/* Dark mode support */
.dark-mode header nav.leftMenu > a.active,
.dark-mode header .rightMenu > a.active {
  background-color: rgba(17, 94, 23, 0.2);
}

.dark-mode header nav.leftMenu > a.active::before,
.dark-mode header .rightMenu > a.active::before {
  background-color: #1a9123;
}

.dark-mode header nav.leftMenu > a:hover,
.dark-mode header .rightMenu > a:hover {
  background-color: rgba(17, 94, 23, 0.1);
}
