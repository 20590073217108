.userNote {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 8px auto;
}

.userNote-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background-color: #31c48d;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.userNote-button:hover {
  background-color: #28a376;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.userNote-button.has-note {
  background-color: #2563eb;
}

.userNote-button.has-note:hover {
  background-color: #1d4ed8;
}

.userNote-editor {
  width: 100%;
  margin-top: 12px;
  background: white;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.userNote-editor textarea {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border: 0px;
  border-radius: 6px;
  resize: vertical;
  font-size: 0.95em;
  line-height: 1.5;
  transition: border-color 0.2s ease;
  box-sizing: border-box;
}

.userNote-editor textarea:focus {
  outline: none;
  border-color: #31c48d;
  box-shadow: 0 0 0 3px rgba(49, 196, 141, 0.1);
}

.userNote-save {
  margin-top: 12px;
  padding: 8px 16px;
  background-color: #31c48d;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.userNote-save:hover {
  background-color: #28a376;
  transform: translateY(-1px);
}

.userNote-display {
  width: 100%;
  margin-top: 8px;
  padding: 12px 16px;
  background-color: #f8fafc;
  border-left: 4px solid #31c48d;
  border-radius: 6px;
  color: #4b5563;
  font-size: 0.95em;
  line-height: 1.5;
  white-space: pre-wrap;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

/* Dark mode support */
.dark-mode .userNote-editor {
  background-color: #1f2937;
}

.dark-mode .userNote-editor textarea {
  background-color: #111827;
  border-color: #374151;
  color: #e5e7eb;
}

.dark-mode .userNote-editor textarea:focus {
  border-color: #1a9123;
  box-shadow: 0 0 0 3px rgba(26, 145, 35, 0.1);
}

.dark-mode .userNote-display {
  background-color: #111827;
  border-left-color: #1a9123;
  color: #e5e7eb;
}

/* Mobile responsiveness */
@media only screen and (max-width: 640px) {
  .userNote {
    padding: 0 12px;
  }

  .userNote-button {
    justify-content: center;
  }

  .userNote-editor {
    padding: 10px;
  }

  .userNote-editor textarea {
    min-height: 100px;
  }

  .userNote-display {
    font-size: 0.9em;
    text-align: center;
  }
}
