/* Rating Progress Bar */

ul.postslist .post .post-rating-progress-bar {
  margin: 6px 16px 10px;
  background: rgba(0, 0, 0, 0.03);
  padding: 14px 18px;
  border-radius: 12px;
  transition: background-color 0.2s ease;
}

ul.postslist .post .post-rating-progress-bar:hover {
  background: rgba(0, 0, 0, 0.04);
}

.dark-mode ul.postslist .post .post-rating-progress-bar {
  background: rgba(255, 255, 255, 0.03);
}

.dark-mode ul.postslist .post .post-rating-progress-bar:hover {
  background: rgba(255, 255, 255, 0.04);
}

ul.postslist .post .post-rating-progress-bar .post-rating-progress {
  width: 190px;
  height: 5px;
  border-radius: 4px;
}

ul.postslist .post .post-rating-progress-bar .percent-display {
  margin-left: 14px;
  font-size: 0.92em;
  display: flex;
  align-items: center;
  gap: 10px;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

/* Add percentage number styling */
ul.postslist .post .post-rating-progress-bar .percent-display::before {
  content: attr(data-percent);
  padding: 6px 6px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.045);
  min-width: 3.2em;
  text-align: center;
  transition: all 0.2s ease;
}

.dark-mode
  ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display::before {
  background: rgba(255, 255, 255, 0.045);
}

ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display.rating-good::before {
  color: rgb(49, 196, 141);
  background: rgba(49, 196, 141, 0.1);
}

.dark-mode
  ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display.rating-good::before {
  color: #1eb32b;
  background: rgba(30, 179, 43, 0.1);
}

ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display.rating-bad::before {
  color: rgb(237, 59, 80);
  background: rgba(237, 59, 80, 0.1);
}

ul.postslist .post .post-rating-progress-bar .percent-display .rating-count {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.04);
  transition: all 0.2s ease;
  font-weight: 500;
}

.dark-mode
  ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count {
  background: rgba(255, 255, 255, 0.04);
}

ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count:hover {
  background: rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

.dark-mode
  ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count:hover {
  background: rgba(255, 255, 255, 0.08);
}

ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count.ratingGood {
  color: rgb(49, 196, 141);
}

.dark-mode
  ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count.ratingGood {
  color: #1eb32b;
}

ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count.ratingBad {
  color: rgb(237, 59, 80);
}

ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count
  svg {
  font-size: 0.9em;
  transition: transform 0.2s ease;
}

ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count:hover
  svg {
  transform: scale(1.1);
}

ul.postslist .post .post-rating-progress-bar .percent-display.rating-good {
  color: rgb(49, 196, 141);
}

.dark-mode
  ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display.rating-good {
  color: #1eb32b;
}

ul.postslist .post .post-rating-progress-bar .percent-display.rating-bad {
  color: rgb(237, 59, 80);
}

/* Remove the old span separator styling */
ul.postslist .post .post-rating-progress-bar .percent-display span {
  display: none;
}
